import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../utils/siteVars';
import Button from './Button';

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  button {
    &:disabled {
      border: 1px solid ${colors.darkGrey};
    }
  }
  .tab {
    background: white;
    padding: 0.3rem 0.6rem;
    border-radius: 8px;
    color: ${colors.mediumGrey};
    cursor: pointer;
    font-weight: 500;
    transition: 200ms ease-in-out;
    &.active {
      background: ${colors.lightBlue};
      color: white;
    }
  }
  @media (max-width: 800px) {
    .tab {
      display: none;
    }
  }
`;

const Pagination = ({ limit, page, setPage, numberOfCustomers }) => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const numberOfTabs = Math.ceil(numberOfCustomers / limit);
    const arr = [];
    for (let i = 0; i < numberOfTabs; i++) {
      arr.push({ i, active: page === i });
    }
    setTabs(arr);
  }, [limit, page, numberOfCustomers]);

  if (numberOfCustomers === undefined) return null;
  return (
    <StyledPagination>
      <Button onClick={() => setPage(page - 1)} small disabled={page === 0}>
        Föregående
      </Button>
      {tabs.map((tab) => (
        //eslint-disable-next-line
        <div
          onClick={() => setPage(tab.i)}
          className={`tab ${tab.active ? 'active' : ''}`}
          key={`pagination_tab__${tab.i}`}
        >
          {tab.i + 1}
        </div>
      ))}
      <Button
        onClick={() => setPage(page + 1)}
        small
        disabled={page === tabs.length - 1}
      >
        Nästa
      </Button>
    </StyledPagination>
  );
};

export default Pagination;
