import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { parseDate } from '../utils/parseDate';
import { colors } from '../utils/siteVars';
import Card from './Card';

const StyledCustomerCard = styled.div`
  .card {
    transition: 200ms ease-in-out;

    .customer-card-grid {
      display: grid;
      grid-template-columns: 6fr 4fr 4fr 4fr;
    }
    .customer-card-headers {
      color: ${colors.mediumGrey};
      transition: 100ms ease-in-out;
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
  }
  ${(props) =>
    props.inner
      ? 'p{display: none; &.mobile-visible{display: initial}} .card .customer-card-grid{grid-template-columns: 1fr 1fr;}'
      : ''}
  &:hover {
    .card {
      background: ${colors.lighterBlue};
      .customer-card-headers {
        color: ${colors.white};
      }
    }
  }
  @media (max-width: 1000px) {
    .card {
      .customer-card-grid {
        grid-template-columns: 2fr 1fr;
      }
    }
    p {
      display: none;
      &.mobile-visible {
        display: initial;
      }
    }
  }
`;

const CustomerCard = (props) => {
  const { customer } = props;
  const { customerInformation } = customer;
  return (
    <StyledCustomerCard
      className="customer-grid"
      inner={props.inner}
      data-test={`customer-card`}
    >
      <Link to={`/customers/single/?id=${customer._id}`}>
        <Card className="card" inner={props.inner}>
          <div className="customer-card-grid customer-card-headers">
            <p className="mobile-visible">Namn</p>
            <p className="mobile-visible">Län</p>
            <p>Sista kontakt</p>
            <p>Serviceavtal slutdatum</p>
          </div>
          <div className="customer-card-grid customer-card-information">
            <p className="mobile-visible">
              <strong>{customerInformation.name || '—'}</strong>
            </p>
            <p className="mobile-visible">
              {customerInformation.county || '—'}
            </p>
            <p>{parseDate(customer.lastContact) || '—'}</p>
            <p>{parseDate(customer.serviceDeal?.endDate) || '—'}</p>
          </div>
        </Card>
      </Link>
    </StyledCustomerCard>
  );
};

export default CustomerCard;
